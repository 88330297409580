import React from "react";
import { graphql } from 'gatsby'
import MDEditor from '@uiw/react-md-editor';
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Newmainpagetemplate = ({data,location}) =>{
  var Index = location.state != null ? location.state.index : 0;
  console.log(data)
  var newannouncementtemplate = data.strapiNewAnnouncements.NewAnnouncementComponent
  return (
    <Layout>
      <div className="card" style={{boxShadow: '5px 10px 18px #888888',width:"82%"}}>
      <Link to="/Announcement">
      {' '}
      <span style={{ fontSize: '25px' }}>←</span> Back to All Announcement
      </Link>
      <MDEditor.Markdown source={newannouncementtemplate[Index].PageContent}/>
      </div>
      </Layout>
    )
}

export const query = graphql`
query newannouncement_template($Slug: String!){
    strapiNewAnnouncements(NewAnnouncementComponent: {elemMatch: {link: {eq: $Slug}}}) {
        NewAnnouncementComponent {
          PageContent
          link
        }
      }
  } 
`


export default Newmainpagetemplate